import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import Spinner from "../Components/Spinner";
import { submitNewPassword } from "../PtoAuth";
import Nav from "../Components/Nav";
import { getLoginUrl, getRegisterUrl } from "../Urls";
import Main from "../Components/Main";
import { getPasswordErrorMessage } from "../Utils";

function SubmitNewPasswordPage(props) {
  const [username, setUsername] = useState(props.emailAddress);
  const [code, setCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [canSubmitPassword, setCanSubmitPassword] = useState(false);
  const [isSubmittingPassword, setIsSubmittingPassword] = useState(false);
  const [submitPasswordError, setSubmitPasswordError] = useState(null);
  const [redirectToLogin, setRedirectToLogin] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    setIsSubmittingPassword(true);

    try {
      await submitNewPassword(username, code, newPassword);
      setIsSubmittingPassword(false);
      setRedirectToLogin(true);
    } catch (err) {
      setIsSubmittingPassword(false);
      setSubmitPasswordError(getPasswordErrorMessage(err));
    }
  };

  const setCanSubmitPassword2 = (username, code, newPassword) => {
    setCanSubmitPassword(username && code && newPassword);
  };

  const onUsernameChange = (e) => {
    const username = e.target.value;
    setUsername(username);

    setCanSubmitPassword2(username, code, newPassword);
  };

  const onCodeChange = (e) => {
    const code = e.target.value;
    setCode(code);

    setCanSubmitPassword2(username, code, newPassword);
  };

  const onNewPasswordChange = (e) => {
    const newPassword = e.target.value;
    setNewPassword(newPassword);

    setCanSubmitPassword2(username, code, newPassword);
  };

  if (redirectToLogin) {
    return <Redirect to={getLoginUrl(username, false)} />;
  }

  return (
    <>
      <Nav onLoggedOut={async () => await props.onLoggedOut()} />
      <Main>
        <h2>Submit New Password</h2>

        <p>
          Check your email for a code which will enable you to reset your
          password.
        </p>

        <div className="row">
          <div className="col-md-8 col-lg-6 col-xl-4">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label>Email address</label>
                <input
                  type="text"
                  className="form-control"
                  name="username"
                  onChange={(e) => onUsernameChange(e)}
                  value={username}
                />
              </div>
              <div className="form-group">
                <label>Code (from email)</label>
                <input
                  type="text"
                  className="form-control"
                  name="code"
                  onChange={(e) => onCodeChange(e)}
                  value={code}
                />
              </div>
              <div className="form-group">
                <label>New password</label>
                <input
                  type="password"
                  className="form-control"
                  name="newPassword"
                  onChange={(e) => onNewPasswordChange(e)}
                  value={newPassword}
                />
              </div>
              <div className="form-group">
                <input
                  type="submit"
                  className="btn btn-primary mr-3"
                  value="Submit new password"
                  disabled={!canSubmitPassword}
                />
                <Spinner showSpinner={isSubmittingPassword} />
              </div>
              <div className="form-group text-danger">
                {submitPasswordError}
              </div>
            </form>
          </div>
        </div>

        <h4>More ...</h4>

        <ul>
          <li>
            <Link to={getLoginUrl(username, false)}>I want to log in</Link>
          </li>
          <li>
            <Link to={getRegisterUrl(username)}>
              I want to create a new account
            </Link>
          </li>
        </ul>
      </Main>
    </>
  );
}

export default SubmitNewPasswordPage;
