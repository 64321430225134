import React, { useState } from "react";
import { signUp } from "../PtoAuth";
import Spinner from "../Components/Spinner";
import { Redirect, Link } from "react-router-dom";
import Nav from "../Components/Nav";
import {
  getConfirmEmailAddressUrl,
  getForgotPasswordUrl,
  getLoginUrl,
} from "../Urls";
import Main from "../Components/Main";
import SupportLink from "../Components/SupportLink";
import { getPasswordErrorMessage } from "../Utils";

function RegisterPage(props) {
  const [username, setUsername] = useState(props.emailAddress);
  const [password, setPassword] = useState("");
  const [isRegistering, setIsRegistering] = useState(false);
  const [redirectToConfirmation, setRedirectToConfirmation] = useState(false);
  const [registerError, setRegisterError] = useState(null);
  const [canRegister, setCanRegister] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    setIsRegistering(true);

    try {
      await signUp(username, password);
      setIsRegistering(false);
      setRedirectToConfirmation(true);
    } catch (err) {
      setIsRegistering(false);
      setRegisterError(getPasswordErrorMessage(err));
    }
  };

  const setCanRegister2 = (username, password) => {
    setCanRegister(username && password);
  };

  const onUsernameChange = (e) => {
    const username = e.target.value;
    setUsername(username);

    setCanRegister2(username, password);
  };

  const onPasswordChange = (e) => {
    const password = e.target.value;
    setPassword(password);

    setCanRegister2(username, password);
  };

  if (redirectToConfirmation) {
    return <Redirect to={getConfirmEmailAddressUrl(username)} />;
  }

  return (
    <>
      <Nav onLoggedOut={async () => await props.onLoggedOut()} />
      <Main>
        <h2>Register</h2>

        <div className="row">
          <div className="col-md-8 col-lg-6 col-xl-4">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label>Email address</label>
                <input
                  type="text"
                  className="form-control"
                  name="username"
                  defaultValue={username}
                  onChange={(e) => onUsernameChange(e)}
                  value={username}
                />
              </div>
              <div className="form-group">
                <label>Password</label>
                <input
                  type="password"
                  className="form-control"
                  name="password"
                  onChange={(e) => onPasswordChange(e)}
                  value={password}
                />
              </div>
              <div className="form-group">
                <input
                  type="submit"
                  className="btn btn-primary mr-3"
                  value="Register"
                  disabled={!canRegister}
                />
                <Spinner showSpinner={isRegistering} />
              </div>
              <div className="form-group text-danger">{registerError}</div>
            </form>
          </div>
        </div>

        <h4>More</h4>
        <ul>
          <li>
            <Link to={getLoginUrl(username, false)}>I want to log in</Link>
          </li>
          <li>
            <Link to={getConfirmEmailAddressUrl(username)}>
              I've been sent a code to confirm my email address
            </Link>
          </li>
          <li>
            <Link to={getForgotPasswordUrl(username)}>
              I forgot my password!
            </Link>
          </li>
          <li>
            Having trouble? Email <SupportLink /> with any questions.
          </li>
        </ul>
      </Main>
    </>
  );
}

export default RegisterPage;
